import styled, { css } from 'styled-components';
import { Spinner } from './Spinner';

type ButtonSize = 'lg' | 'md';
type ButtonKind = 'primary' | 'secondary' | 'contrast';

type ButtonFill = 'none' | 'solid';

const sharedStyle = css<{
    size: ButtonSize;
    kind: ButtonKind;
    fill: ButtonFill;
    inProgress?: boolean;
}>`
    border-radius: ${({ size }) => (size === 'lg' ? '30px' : '20px')};
    border: 2px solid
        ${({ kind, theme }) => {
            switch (kind) {
                case 'primary':
                    return theme.colors.primary;
                case 'secondary':
                    return theme.colors.secondary;
                case 'contrast':
                    return theme.text.contrast;
            }
        }};
    font-size: ${({ size }) => (size === 'lg' ? '23px' : '18px')};
    font-weight: 700;
    padding: ${({ size }) => (size === 'lg' ? '16px 28px' : '10px 28px')};
    appearance: none;
    background: ${({ theme, fill }) => (fill !== 'none' ? theme.text.contrast : 'none')};
    color: ${({ theme, fill }) => (fill === 'none' ? theme.text.contrast : theme.colors.main)};
    min-width: ${({ size }) => (size === 'lg' ? '208px' : '118px')};
    cursor: pointer;
    box-sizing: border-box;
`;

const StyledButton = styled.button<{
    size: ButtonSize;
    kind: ButtonKind;
    fill: ButtonFill;
    inProgress?: boolean;
}>`
    ${sharedStyle}

    &:disabled {
        cursor: default;
        opacity: ${({ inProgress }) => (inProgress ? 1.0 : 0.6)};
    }
`;

const StyledLink = styled.a<{
    size: ButtonSize;
    kind: ButtonKind;
    fill: ButtonFill;
    inProgress?: boolean;
}>`
    ${sharedStyle}
`;

type Props = {
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactNode;
    size?: ButtonSize;
    kind?: ButtonKind;
    fill?: ButtonFill;
} & (
    | {
          type?: 'submit' | 'button';
          onClick?: () => void;
          disabled?: boolean;
          inProgress?: boolean;
      }
    | {
          type: 'link';
          href: string;
      }
);

export const Button: React.FunctionComponent<Props> = (props: Props) => {
    const { className, style, children, size = 'md', kind = 'primary', fill = 'none' } = props;
    if (props.type !== 'link') {
        const { disabled, onClick, inProgress, type } = props;
        return (
            <StyledButton
                type={type || (onClick ? 'button' : 'submit')}
                disabled={disabled || inProgress}
                inProgress={inProgress}
                className={className}
                fill={fill}
                kind={kind}
                size={size}
                style={style}
                onClick={onClick}
            >
                {inProgress ? <Spinner contrast={fill !== 'solid'} /> : children}
            </StyledButton>
        );
    } else {
        const { href } = props;
        return (
            <StyledLink href={href} className={className} fill={fill} kind={kind} size={size} style={style}>
                {children}
            </StyledLink>
        );
    }
};
