import { useTranslation } from 'next-i18next';
import Head from 'next/head';
import { useRouter } from 'next/router';

export const WebsiteHead = ({
    metaTitle,
    metaDescription,
}: React.PropsWithChildren<{ metaTitle?: string; metaDescription?: string }>) => {
    const router = useRouter();
    const isFr = router.locale === 'fr';
    const { t } = useTranslation('common');
    const description = t('app-description');
    const isProd = process.env.NEXT_PUBLIC_APP_ENV === 'prod';

    return (
        <Head>
            <title>{metaTitle ? metaTitle : 'MagicShip: Shipping Software for eCommerce Fulfillment'}</title>
            <meta name="description" content={metaDescription ? metaDescription : description} />
            <link rel="icon" href="/favicon.ico" />
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/site.webmanifest" />
            {/* <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" /> */}
            <meta name="apple-mobile-web-app-title" content="MagicShip" />
            <meta name="application-name" content="MagicShip" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="theme-color" content="#ffffff" />

            <meta property="og:url" content="https://magicship.com/" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="MagicShip" />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={`https://magicship.com/logo-1200x630${isFr ? '-fr' : ''}.jpg`} />
            {isProd && <meta name="facebook-domain-verification" content="3tru8b6r65fa7ncfvdp3e3s8ozs373" />}

            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:domain" content="magicship.com" />
            <meta property="twitter:url" content="https://magicship.com/" />
            <meta name="twitter:title" content="MagicShip" />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={`https://magicship.com/logo-600x600${isFr ? '-fr' : ''}.jpg`} />
        </Head>
    );
};
