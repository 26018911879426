import React, { useContext, useMemo } from 'react';

type Modal = 'login' | 'signup' | 'resetPassword';

export const ModalContext = React.createContext<{
    modal: Modal | null;
    showModal: (modal: Modal | null) => void;
}>({
    modal: null,
    showModal: (modal: Modal | null) => {
        return modal;
    },
});

export const useModalContext = () => {
    const context = useContext(ModalContext);
    return context;
};

export const ModalContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [modal, setModal] = React.useState<Modal | null>(null);
    const value = useMemo(
        () => ({
            modal,
            showModal: setModal,
        }),
        [setModal, modal]
    );
    return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};
