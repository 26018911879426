import styled from 'styled-components';
import { Breakpoints } from 'styles/theme';

export const Content = styled.div<{ horizontal?: boolean }>`
    display: flex;
    flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
    justify-content: ${({ horizontal }) => (horizontal ? 'space-between' : 'normal')};
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1256px;
    padding: 0 28px;
    box-sizing: border-box;
`;

export const NegativeOffset = styled.div`
    margin: 0 -28px;
    align-self: stretch;

    @media ${Breakpoints.lg} {
        margin: 0;
    }
`;
