import styled from 'styled-components';

export const Spinner = styled.div<{ contrast?: boolean; size?: 'md' | 'lg' }>`
    position: relative;

    &:after {
        content: 'L';
        visibility: hidden;
    }

    @keyframes spinner {
        to {
            transform: rotate(360deg);
        }
    }

    &::before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: ${({ size }) => (size === 'lg' ? 40 : 20)}px;
        height: ${({ size }) => (size === 'lg' ? 40 : 20)}px;
        margin-top: ${({ size }) => (size === 'lg' ? -20 : -10)}px;
        margin-left: ${({ size }) => (size === 'lg' ? -20 : -10)}px;
        border-radius: 50%;
        border: ${({ size }) => (size === 'lg' ? 4 : 2)}px solid
            ${({ contrast, theme }) => (!contrast ? theme.colors.main : theme.text.contrast)};
        border-top-color: transparent;
        animation: spinner 0.6s linear infinite;
    }
`;
