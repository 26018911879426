import styled from 'styled-components';
import Image from 'next/image';
import logoImage from 'public/logo-large.svg';
import { Breakpoints } from 'styles/theme';
import plusImage from 'public/plus.svg';
import closeImage from 'public/close.svg';

import { Button } from 'components/atoms/Button';
import { useTranslation } from 'next-i18next';
import { useCallback, useState } from 'react';
import { Content } from 'components/atoms/Content';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { Auth } from '@aws-amplify/auth';
import { useCurrentUser } from 'components/hooks/useCurrentUser';
import { useModalContext } from './login/ModalContext';

const Items = styled.div`
    display: flex;
    align-items: center;

    > :not(:last-child) {
        margin-right: 26px;
    }
`;

const DropdownItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    &:not(:last-child) {
        margin-bottom: 42px;
    }

    > :not(:last-child) {
        margin-bottom: 26px;
    }
`;

const ToggleButton = styled.button`
    background: none;
    border: none;
    appearance: none;
    cursor: pointer;
`;

const Dropdown = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: ${({ theme }) => theme.colors.main};
    display: flex;
    flex-direction: column;
    padding: 28px 0px;
    align-items: center;
    z-index: 100;
`;

const HeaderContainer = styled.header`
    background: ${({ theme }) => theme.colors.main};
    color: ${({ theme }) => theme.text.contrast};
    min-height: 90px;
    display: flex;
    position: relative;

    a {
        color: ${({ theme }) => theme.text.contrast};
        margin: 0;
    }

    ${Items} {
        display: none;
    }

    @media ${Breakpoints.md} {
        ${Items} {
            display: inherit;
        }

        ${ToggleButton} {
            display: none;
        }

        ${Dropdown} {
            display: none;
        }
    }
`;

const ImageLink = styled.a`
    line-height: 0;
`;

type Props = {
    isLoggedIn?: boolean;
};

export const Header: React.FunctionComponent<Props> = ({ isLoggedIn }: Props) => {
    const router = useRouter();
    const user = useCurrentUser();
    const { showModal } = useModalContext();

    const handleSignup = useCallback(() => {
        showModal('signup');
    }, [showModal]);

    const handleLogin = useCallback(() => {
        showModal('login');
    }, [showModal]);

    const handleLogout = useCallback(async () => {
        await Auth.signOut();
        window.location.replace('/');
    }, []);

    const { t } = useTranslation('common');
    const [expanded, setExpanded] = useState(false);
    const toggleExpanded = useCallback(() => setExpanded((value) => !value), []);
    let links: React.ReactNode = null;
    let actions: React.ReactNode = null;
    if (!isLoggedIn && !user) {
        actions = (
            <>
                <Link href={'/fulfillment-service/canada'} passHref>
                    {t('Fulfillment Service')}
                </Link>
                <Button onClick={handleSignup}>{t('Sign up')}</Button>
                <Button kind="secondary" onClick={handleLogin}>
                    {t('Login')}
                </Button>
            </>
        );
        links = null;
    } else {
        const nonDashboardPaths = ['/tos', '/privacy-policy', '/fulfillment-service/canada', '/'];
        if (nonDashboardPaths.indexOf(router.pathname) !== -1) {
            actions = (
                <>
                    <Link href={'/fulfillment-service/canada'} passHref>
                        {t('Fulfillment Service')}
                    </Link>

                    <Link href={'/shipments'} passHref>
                        <Button type="link" href="">
                            {t('Dashboard')}
                        </Button>
                    </Link>
                    <Button kind="secondary" onClick={handleLogout}>
                        {t('Logout')}
                    </Button>
                </>
            );
        } else {
            actions = (
                <>
                    <Link href={'/fulfillment-service/canada'} passHref>
                        {t('Fulfillment Service')}
                    </Link>
                    <Button type="link" href="mailto:contact@magicship.com">
                        {t('Contact')}
                    </Button>
                    <Button kind="secondary" onClick={handleLogout}>
                        {t('Logout')}
                    </Button>
                </>
            );
        }

        links = (
            <>
                <Link href="/new-shipment">
                    <a>{t('New Shipment')}</a>
                </Link>
                <Link href="/shipments">
                    <a>{t('Shipments')}</a>
                </Link>
                {!user?.isInternal && (
                    <Link href="/billing">
                        <a>{t('Billing')}</a>
                    </Link>
                )}
                <Link href="/settings">
                    <a>{t('Settings')}</a>
                </Link>
            </>
        );
    }
    return (
        <HeaderContainer>
            <Content horizontal>
                <Link href="/" passHref>
                    <ImageLink>
                        <Image src={logoImage} alt="MagicShip logo" width="170" height="32" />
                    </ImageLink>
                </Link>
                <Items>{actions}</Items>
                <ToggleButton onClick={toggleExpanded}>
                    {' '}
                    <Image src={expanded ? closeImage : plusImage} alt="expand" width="26" height="26" />{' '}
                </ToggleButton>
                {expanded && (
                    <Dropdown>
                        <DropdownItems>{links}</DropdownItems>
                        <DropdownItems>{actions}</DropdownItems>
                    </Dropdown>
                )}
            </Content>
        </HeaderContainer>
    );
};
