import styled from 'styled-components';
import Image from 'next/image';
import logoImage from 'public/logo-large.svg';
import { Button } from 'components/atoms/Button';
import { useTranslation } from 'next-i18next';
import { Breakpoints } from 'styles/theme';
import { Content } from 'components/atoms/Content';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

const Container = styled.footer`
    background: ${({ theme }) => theme.colors.main};
    color: ${({ theme }) => theme.text.contrast};
    text-align: center;
    padding: 30px 0;
    display: flex;

    a {
        color: ${({ theme }) => theme.text.contrast};
    }

    @media ${Breakpoints.md} {
        padding: 30px 0 30px;
    }
`;

const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;

    & > * {
        margin-bottom: 50px;
    }

    @media ${Breakpoints.md} {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media ${Breakpoints.md} {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const LinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * {
        margin-bottom: 12px;
    }

    @media ${Breakpoints.md} {
        flex-direction: row;

        & > a {
            margin-right: 40px;
            margin-bottom: 0;
        }
    }
`;

const LanguageLink = styled.a`
    cursor: pointer;
`;

export const Footer: React.FunctionComponent = () => {
    const { t } = useTranslation('common');
    const router = useRouter();
    const handleLanguageSwitch = useCallback(() => {
        const newLocale = router.locale === 'en' ? 'fr' : 'en';
        document.cookie = `NEXT_LOCALE=${newLocale}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
        router.push(`/${newLocale}`);
        const { pathname, asPath, query } = router;
        router.push({ pathname, query }, asPath, { locale: newLocale });
    }, [router]);
    return (
        <Container>
            <Content>
                <LogoContainer>
                    <div>
                        <Image src={logoImage} alt="logo" width={214} height={41} />
                    </div>
                    <Button size="lg" type="link" href="mailto:contact@magicship.com">
                        {t('Contact')}
                    </Button>
                </LogoContainer>
                <InfoContainer>
                    <LinksContainer>
                        <Link href="/tos">{t('Terms & Conditions')}</Link>
                        <Link href="/privacy-policy">{t('Privacy Policy')}</Link>
                        <LanguageLink onClick={handleLanguageSwitch}>{t('switch-language')}</LanguageLink>
                    </LinksContainer>
                    <div>{t('Copyright')} © MagicShip 2022</div>
                </InfoContainer>
            </Content>
        </Container>
    );
};
