import { LengthUnit, WeightUnit } from "~graphql/API";

export const errorToMessage = (error: unknown): string => {
    if (error) {
        if (error instanceof Error) {
            return error.message;
        } else if (error && typeof error === 'object' && 'message' in error) {
            return (error as Record<string, string>).message;
        } else if (error && typeof error === 'object' && 'errors' in error) {
            return errorToMessage((error as Record<string, string>).errors[0]);
        } else {
            return `${error}`;
        }
    }
    return '';
}

export const ensureArray = <T>(links: T | T[]) => Array.isArray(links) ? links : [links]

export const encodeXmlValue = (value: string) => {
    return value.replace(/[<>&'"]/g, (c: string) => {
        switch (c) {
            case '<': return '&lt;';
            case '>': return '&gt;';
            case '&': return '&amp;';
            case '\'': return '&apos;';
            case '"': return '&quot;';
        }
        return c;
    });
}

export const capitalize = (value: string): string => {
    return value.split(" ").map(x => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase()).join(" ")
}

const LBS_PER_KG = 2.20462;
const INCH_PER_CM = 0.393701;

export const lbsToKg = (value: number) => value / LBS_PER_KG
export const kgToLbs = (value: number) => value * LBS_PER_KG
export const inchToCm = (value: number) => value / INCH_PER_CM
export const cmToInch = (value: number) => value * INCH_PER_CM

const weightConverters: Record<WeightUnit, Record<WeightUnit, number>> = {
    [WeightUnit.kgs]: {
        [WeightUnit.kgs]: 1,
        [WeightUnit.lbs]: LBS_PER_KG,
    },
    [WeightUnit.lbs]: {
        [WeightUnit.kgs]: 1 / LBS_PER_KG,
        [WeightUnit.lbs]: 1,
    }
}

const lengthConverters: Record<LengthUnit, Record<LengthUnit, number>> = {
    [LengthUnit.cm]: {
        [LengthUnit.cm]: 1,
        [LengthUnit.in]: INCH_PER_CM,
    },
    [LengthUnit.in]: {
        [LengthUnit.cm]: 1 / INCH_PER_CM,
        [LengthUnit.in]: 1,
    }
}


export const roundWeight = (number: number) => Math.round((number + Number.EPSILON) * 1000) / 1000

export const convertWeight = (value: number, from: WeightUnit, to: WeightUnit) => {
    const multiplier = weightConverters[from][to]
    return roundWeight(value * multiplier);
}

const roundLength = (number: number) => Math.round((number + Number.EPSILON) * 10) / 10

export const convertLength = (value: number, from: LengthUnit, to: LengthUnit) => {
    const multiplier = lengthConverters[from][to]
    return roundLength(value * multiplier);
}


export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))